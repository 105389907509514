// docs: https://dev.azure.com/wristohoi-setapp/Wrist%20Generate/_wiki/wikis/Wrist-Generate.wiki/132/Feature-flags
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { useManningReports } from '../hooks/useManningReports';
import { datadogRum } from '@datadog/browser-rum';
import { getCurrentMonthForStocktakingWithoutHooks } from './utils/getCurrentMonthForStocktakingWithoutHooks';

export interface FeatureFlags {
    claims: boolean;
    queueDebugMode: boolean;
    offlineExperienceV2: boolean;
    condemn: boolean;
    receival: boolean;
    performanceReport: boolean;
    manning: boolean;
    stocktaking: boolean;
    manningReopen: boolean;
    stocktakingReopen: boolean;
    stocktakingPrinting: boolean;
    cashPurchase: boolean;
    vrRates: boolean;
    claimsInReceival: boolean;
    condemnStandalone: boolean;
    seasonalPurchase: boolean;
    seasonalPurchaseType: string;
    hidePrice: boolean;
}

interface ContextValue {
    featureFlags: FeatureFlags | undefined;
}

type FeatureFlagsProviderProps = {
    children: React.ReactNode;
};

const FeatureFlagsContext = createContext<ContextValue | undefined>(undefined);

export const claimsNamespace = 'https://garrets.com';

const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
    const [featureFlags, setFeatureFlags] = useState<
        undefined | FeatureFlags
    >();
    const { isLoading, isAuthenticated, user } = useAuth0();

    const { currentMonth: manningCurrentMonth } = useManningReports({
        withReopen: featureFlags?.manningReopen,
    });

    const getFeatureFlagsFromAuth0Metadata = (
        auth0User: User,
    ): Record<keyof FeatureFlags, string> => {
        return {
            claims: auth0User[`${claimsNamespace}/FEATURE_CLAIMS`],
            queueDebugMode: auth0User[`${claimsNamespace}/QUEUE_DEBUG_MODE`],
            offlineExperienceV2:
                auth0User[`${claimsNamespace}/OFFLINE_EXPERIENCE_V2`],
            condemn: auth0User[`${claimsNamespace}/FEATURE_CONDEMN`],
            receival: auth0User[`${claimsNamespace}/FEATURE_RECEIVAL`],
            performanceReport:
                auth0User[`${claimsNamespace}/PERFORMANCE_REPORT`],
            manning: auth0User[`${claimsNamespace}/FEATURE_MANNING`],
            stocktaking: auth0User[`${claimsNamespace}/FEATURE_STOCKTAKING`],
            manningReopen:
                auth0User[`${claimsNamespace}/FEATURE_MANNING_REOPEN`],
            stocktakingReopen:
                auth0User[`${claimsNamespace}/FEATURE_STOCKTAKING_REOPEN`],
            stocktakingPrinting:
                auth0User[`${claimsNamespace}/FEATURE_STOCKTAKING_PRINTING`],
            cashPurchase: auth0User[`${claimsNamespace}/FEATURE_CASH_PURCHASE`],
            seasonalPurchase: auth0User[`${claimsNamespace}/FEATURE_SEASONAL`],
            seasonalPurchaseType: auth0User[`${claimsNamespace}/SEASONAL_ORDER_TYPE`],
            vrRates: auth0User[`${claimsNamespace}/FEATURE_VR_RATES`],
            claimsInReceival:
                auth0User[`${claimsNamespace}/FEATURE_CLAIMS_IN_RECEIVAL`],
            condemnStandalone:
                auth0User[`${claimsNamespace}/FEATURE_CONDEMN_STANDALONE`],
            hidePrice:
                auth0User[`${claimsNamespace}/FEATURE_HIDE_PRICE`]
        };
    };

    const getFeatureFlagsFromEnvVariables = (): Record<
        keyof FeatureFlags,
        string | undefined
    > => {
        return {
            claims: process.env.NEXT_PUBLIC_FEATURE_CLAIMS,
            queueDebugMode: process.env.NEXT_PUBLIC_QUEUE_DEBUG_MODE,
            offlineExperienceV2: process.env.NEXT_PUBLIC_OFFLINE_EXPERIENCE_V2,
            condemn: process.env.NEXT_PUBLIC_FEATURE_CONDEMN,
            receival: process.env.NEXT_PUBLIC_FEATURE_RECEIVAL,
            performanceReport: process.env.NEXT_PUBLIC_PERFORMANCE_REPORT,
            manning: process.env.NEXT_PUBLIC_FEATURE_MANNING,
            stocktaking: process.env.NEXT_PUBLIC_FEATURE_STOCKTAKING,
            manningReopen: process.env.NEXT_PUBLIC_FEATURE_MANNING_REOPEN,
            stocktakingReopen:
                process.env.NEXT_PUBLIC_FEATURE_STOCKTAKING_REOPEN,
            stocktakingPrinting:
                process.env.NEXT_PUBLIC_FEATURE_STOCKTAKING_PRINTING,
            cashPurchase: process.env.NEXT_PUBLIC_FEATURE_CASH_PURCHASE,
            seasonalPurchase: process.env.NEXT_PUBLIC_FEATURE_SEASONAL_PURCHASE,
            seasonalPurchaseType: process.env.NEXT_PUBLIC_FEATURE_SEASONAL_ORDER_TYPE,
            vrRates: process.env.NEXT_PUBLIC_FEATURE_VR_RATES,
            claimsInReceival:
                process.env.NEXT_PUBLIC_FEATURE_CLAIMS_IN_RECEIVAL,
            //Introducing it here since all auth0 vessels are setup to use "condemn" flag and we need to hide one in navigation and leave option to do it during stocktaking
            condemnStandalone:
                process.env.NEXT_PUBLIC_FEATURE_CONDEMN_STANDALONE,
            hidePrice:
                process.env.NEXT_PUBLIC_FEATURE_HIDE_PRICE 
        };
    };

    const parseStringToBool = (string: string | undefined): boolean => {
        if (string === undefined) {
            return false;
        }
        return string === 'true' || string === 'TRUE';
    };

    const updateFeatureFlags = async (
        featureFlagsFromAuth0Metadata: Record<keyof FeatureFlags, string>,
        envFeatureFlags: Record<keyof FeatureFlags, string | undefined>,
        user: User,
    ) => {
        const stocktakingCurrentMonth =
            await getCurrentMonthForStocktakingWithoutHooks(user);

        const featureFlags: FeatureFlags = {
            claims: parseStringToBool(
                featureFlagsFromAuth0Metadata.claims ?? envFeatureFlags.claims,
            ),
            queueDebugMode: parseStringToBool(
                featureFlagsFromAuth0Metadata.queueDebugMode ??
                    envFeatureFlags.queueDebugMode,
            ),
            offlineExperienceV2: parseStringToBool(
                featureFlagsFromAuth0Metadata.offlineExperienceV2 ??
                    envFeatureFlags.offlineExperienceV2,
            ),
            condemn: parseStringToBool(
                featureFlagsFromAuth0Metadata.condemn ??
                    envFeatureFlags.condemn,
            ),
            receival: parseStringToBool(
                featureFlagsFromAuth0Metadata.receival ??
                    envFeatureFlags.receival,
            ),
            performanceReport: parseStringToBool(
                featureFlagsFromAuth0Metadata.performanceReport ??
                    envFeatureFlags.performanceReport,
            ),
            manningReopen: parseStringToBool(
                featureFlagsFromAuth0Metadata.manningReopen ??
                    envFeatureFlags.manningReopen,
            ),
            stocktakingReopen: parseStringToBool(
                featureFlagsFromAuth0Metadata.stocktakingReopen ??
                    envFeatureFlags.stocktakingReopen,
            ),
            stocktakingPrinting: parseStringToBool(
                featureFlagsFromAuth0Metadata.stocktakingPrinting ??
                    envFeatureFlags.stocktakingPrinting,
            ),
            cashPurchase: parseStringToBool(
                featureFlagsFromAuth0Metadata.cashPurchase ??
                    envFeatureFlags.cashPurchase,
            ),
            seasonalPurchase: parseStringToBool(
                featureFlagsFromAuth0Metadata.seasonalPurchase ??
                    envFeatureFlags.seasonalPurchase,
            ),
            seasonalPurchaseType: 
                featureFlagsFromAuth0Metadata.seasonalPurchaseType ??
                    envFeatureFlags.seasonalPurchaseType,
            
            vrRates: parseStringToBool(
                featureFlagsFromAuth0Metadata.vrRates ??
                    envFeatureFlags.vrRates,
            ),
            claimsInReceival: parseStringToBool(
                featureFlagsFromAuth0Metadata.claimsInReceival ??
                    envFeatureFlags.claimsInReceival,
            ),
            // If current month is null, it is most likely that someone forgot about MANNING_OPENING_PERIOD variable in auth0.
            // debuging: Check if the user has historical reports, if not then check if they have MANNING_OPENING_PERIOD set in auth0.
            manning: !manningCurrentMonth
                ? false
                : parseStringToBool(
                      featureFlagsFromAuth0Metadata.manning ??
                          envFeatureFlags.manning,
                  ),
            stocktaking: !stocktakingCurrentMonth
                ? false
                : parseStringToBool(
                      featureFlagsFromAuth0Metadata.stocktaking ??
                          envFeatureFlags.stocktaking,
                  ),
            condemnStandalone: parseStringToBool(
                featureFlagsFromAuth0Metadata.condemnStandalone ??
                    envFeatureFlags.condemnStandalone,
            ),
            hidePrice: parseStringToBool(
                featureFlagsFromAuth0Metadata.hidePrice ??
                    envFeatureFlags.hidePrice
            )
        };

        setFeatureFlags(featureFlags);

        datadogRum.setUser({
            id: user.sub,
            email: user.email,
            name: user.name,
            featureFlags,
        });
    };

    useEffect(() => {
        if (!isAuthenticated || isLoading || !user) {
            return;
        }

        const featureFlagsFromAuth0Metadata =
            getFeatureFlagsFromAuth0Metadata(user);

        const envFeatureFlags = getFeatureFlagsFromEnvVariables();

        updateFeatureFlags(
            featureFlagsFromAuth0Metadata,
            envFeatureFlags,
            user,
        );
    }, [isAuthenticated, isLoading, user]);

    return (
        <FeatureFlagsContext.Provider value={{ featureFlags }}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export { FeatureFlagsProvider };

export const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    if (context === undefined) {
        throw new Error('useFeatureFlags is out of scope');
    }

    return context;
};