import { useRouter } from 'next/router';
import { OrderToDisplay } from '../../types/order.types';
import BackButton from '../BackButton/BackButton';
import styles from './OrderPageTopbar.module.css';
import FilterCheckIcon from '../../public/gfx/uiIcons/filterCheckIcon.svg';
import StatusBadge from '../StatusBadge/StatusBadge';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import CheckoutOrder from '../CheckoutOrder/CheckoutOrder';
import { useStickyTopbar } from '../../hooks/useStickyTopbar';
import OrderOptionsDropdown from './OrderOptionsDropdown';
import { PopupsHandlers } from '../../hooks/usePopupsInSummaryPage';
import { getShortWbPono } from '../utils/wbPono';
import { getOrderTypeName } from '../../context/OrderTypes';
import Button from '../Button/Button';
import { useReceivedOrder } from '../../hooks/useReceivedOrder';
import ConfirmReceivalIcon from '../../public/gfx/buttonIcons/confirmReceivalIcon.svg';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import { getOrderReceivalPath } from '../utils/orderPathsGenerator';
import { ReceivalStep } from '../../pages/order/[orderType]/[orderId]/receival/[receivalStep]';
import { GarretsContactTooltip } from '../Tooltips/GarretsContactTooltip';
import { OrderStatus } from '../../apiClient/generated';
import PlusIcon from '../../public/gfx/uiIcons/add.svg';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import { ModalType, useModalContext } from '../../context/ModalContext';
import { ToastType, useToast } from '../../context/ToastContext';
import { useState } from 'react';
import { useCashPurchaseOrder } from '../../hooks/useCashPurchaseOrder';

interface OrderPageTopbarProps {
    orderToDisplay: OrderToDisplay;
    numChanges?: number;
    popupsHandlers?: PopupsHandlers;
    isInOrderSummaryPage?: boolean;
    onSubmitButtonClick?: () => Promise<void>;
    isSsPoNumberAvailableForOrder?: boolean;
    isOrderValidating?: boolean;
}

const OrderPageTopbar: React.FC<OrderPageTopbarProps> = ({
    orderToDisplay,
    popupsHandlers,
    numChanges,
    isInOrderSummaryPage,
    onSubmitButtonClick,
    isSsPoNumberAvailableForOrder,
    isOrderValidating,
}) => {
    const { featureFlags } = useFeatureFlags();

    const { push } = useRouter();
    const { isPageScrolled } = useStickyTopbar();

    const { openOrderChangelogSideModal, openAddItemsModal } =
        popupsHandlers || {};

    const {
        shouldDisplayChangelog,
        displayedStatus,
        isLocked,
        canOpenReceivalFlow,
    } = parseOrderStatus(orderToDisplay);
 
    const {
        data: editableOrder,
        isEditableOrderSavedAsDraftAlready,
        hasChanges,
        saveDraft,
    } = useEditableOrder(orderToDisplay.localOrderId);

    const { openGlobalModal } = useModalContext();

    const onGoBack = () =>
        orderToDisplay.isSending || isLocked
            ? push('/order?tab=history')
            : push(
                  `/order/${orderToDisplay.type.toLowerCase()}/${
                      orderToDisplay.localOrderId
                  }/preparation`,
              );

    const pageHeadingContent = `${getOrderTypeName(orderToDisplay.type)} #${
        getShortWbPono(orderToDisplay?.rfq.wbPono) || 'Draft'
    }`;

    const selectCtaButton = () => {
        const page = isInOrderSummaryPage ? 'summary' : 'preparation';

        return (
            <CheckoutOrder
                orderToDisplay={orderToDisplay}
                page={page}
                popupsHandlers={popupsHandlers}
                onSubmitButtonClick={onSubmitButtonClick}
            />
        );
    };

    const { data: receivedOrder, isReceivalQueued } = useReceivedOrder(
        orderToDisplay.orderId,
    );

    const { showToast } = useToast();

    const [isSavingDraftInProgress, setIsSavingDraftInProgress] =
        useState(false);

    const onSaveDraft = async () => {
        if (isEditableOrderSavedAsDraftAlready || editableOrder?.status === 3) {
            if (editableOrder) {
                setIsSavingDraftInProgress(true);

                /**
                 * This setTimeout is used as a workaround for the issue with validating input value on blur.
                 * DecimalInput(components/DecimalInput.tsx) in products list has onBlur method that adjusts input value to packSize.
                 * Mentioned method is debounced for performance reasons.
                 * It makes race-condition between saving draft and adjusting inputValue.
                 */
                setTimeout(() => {
                    saveDraft(editableOrder);
                    showToast({
                        type: ToastType.success,
                        text: 'Draft saved',
                    });
                    setIsSavingDraftInProgress(false);
                }, 1000);
            }
        } else {
            openGlobalModal({
                modalType: ModalType.saveDraft,
                orderId: orderToDisplay.localOrderId,
            });
        }
    };

    const { query } = useRouter()
    const orderIdFromQueryCash = query.cashPurchaseId 
 
    const { cashPurchaseOrder } = useCashPurchaseOrder(
        Number(orderIdFromQueryCash),
    );

    const orderToDisplayCash = cashPurchaseOrder

    return (
        <>
            <div
                className={[
                    styles.container,
                    isPageScrolled ? styles.collapsed : '',
                ].join(' ')}
            >
                {isInOrderSummaryPage && (
                    <div className={styles.backButton}>
                        <BackButton
                            onGoBack={onGoBack}
                            caption={
                                orderToDisplay.isSending || isLocked
                                    ? 'Back to list of orders'
                                    : 'Back to products'
                            }
                        />
                    </div>
                )}
                <h2 className={`pageHeading ${styles.withBadge}`}>
                    {pageHeadingContent}{' '}
                    {isOrderValidating ? (
                        <div className={styles.skeletonLoaderContainer}>
                            <SkeletonLoader />
                        </div>
                    ) : (
                        <StatusBadge>
                            {orderToDisplay.isSending
                                ? 'Sending'
                                : displayedStatus}
                        </StatusBadge>
                    )}
                </h2>
                <div className={styles.actionButtons}>
                    
                    {!orderToDisplay.isSending && (
                        <> 
                            <OrderOptionsDropdown 
                                orderToDisplay={orderToDisplay}
                                orderToDisplayCash={orderToDisplayCash}
                                
                                />
                           
                            {[
                                OrderStatus.Blank,
                                OrderStatus.OrderForReview,
                            ].includes(orderToDisplay.status) && (
                                <>
                                    <div
                                        className={styles.addItemsButtonWrapper}
                                    >
                                        <Button
                                            text={`${
                                                isSavingDraftInProgress
                                                    ? 'Saving'
                                                    : isEditableOrderSavedAsDraftAlready ||
                                                      editableOrder?.status ===
                                                          3
                                                    ? 'Save Changes'
                                                    : 'Save Draft'
                                            }`}
                                            smallPaddings
                                            greyCta
                                            smallRounded
                                            onClick={() => onSaveDraft()}
                                            disabled={
                                                !hasChanges ||
                                                isSavingDraftInProgress
                                            }
                                        />
                                    </div>
                                    {isInOrderSummaryPage && (
                                        <div
                                            className={
                                                styles.addItemsButtonWrapper
                                            }
                                        >
                                            <Button
                                                text="Add items"
                                                greyCta
                                                smallRounded
                                                onClick={openAddItemsModal}
                                                leftIcon={
                                                    <PlusIcon
                                                        stroke="#7A808D"
                                                        width="14"
                                                        height="14"
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {selectCtaButton()}

                    {isInOrderSummaryPage && shouldDisplayChangelog && (
                        <button
                            className={styles.changelogButton}
                            onClick={openOrderChangelogSideModal}
                        >
                            <FilterCheckIcon width={20} height={20} />
                            {Boolean(numChanges) && (
                                <div className={styles.changelogButtonBadge}>
                                    {numChanges}
                                </div>
                            )}
                        </button>
                    )}

                    {isInOrderSummaryPage &&
                        canOpenReceivalFlow &&
                        Boolean(featureFlags?.receival) && (
                            <div className={styles.receivalButtonWrapper}>
                                {!isReceivalQueued &&
                                    (isSsPoNumberAvailableForOrder ? (
                                        <Button
                                            smallRounded
                                            blueCta
                                            text={
                                                receivedOrder
                                                    ? 'Continue Receival'
                                                    : 'Confirm Receival'
                                            }
                                            icon={
                                                <ConfirmReceivalIcon
                                                    width="24"
                                                    height="24"
                                                />
                                            }
                                            onClick={async () => {
                                                {
                                                    await push(
                                                        getOrderReceivalPath(
                                                            orderToDisplay,
                                                            ReceivalStep.Start,
                                                        ),
                                                    );
                                                }
                                            }}
                                        />
                                    ) : (
                                        <GarretsContactTooltip additionalTooltipText="This order cannot be received using Gateway because a purchase number is missing. Please contact Garrets:">
                                            <Button
                                                smallRounded
                                                disabled
                                                text={
                                                    receivedOrder
                                                        ? 'Continue Receival'
                                                        : 'Confirm Receival'
                                                }
                                                icon={
                                                    <ConfirmReceivalIcon
                                                        width="24"
                                                        height="24"
                                                    />
                                                }
                                            />
                                        </GarretsContactTooltip>
                                    ))}
                            </div>
                        )}
                </div>
            </div>
            <div className={styles.spacer} />
        </>
    );
};

export default OrderPageTopbar;
